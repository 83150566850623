<template>
  <div class="sceneryLogDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="景区名称">
        <span>{{ list.sceneryName }}</span>
      </el-form-item>
      <el-form-item label="修改人">
        <span>{{ list.masterName }}</span>
      </el-form-item>
      <el-form-item label="修改时间">
        <span>{{ list.addTime }}</span>
      </el-form-item>
      <el-form-item label="修改内容">
        <span>{{ list.strType }}</span>
      </el-form-item>
      <el-form-item label="修改内容">
        <span>{{ list.logType }}</span>
      </el-form-item>
      <el-form-item label="修改前内容">
        <span>{{ list.curContent }}</span>
      </el-form-item>
      <el-form-item label="修改后内容">
        <span>{{ list.endContent }}</span>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
import { sceneryLogDetails } from "../../api/sceneryLog";
export default {
  name: "sceneryLogDetails",
  data() {
    return {
      list: {},
    };
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  created() {
    this.getsceneryLogDetails();
  },
  methods: {
    async getsceneryLogDetails() {
      const { data } = await sceneryLogDetails({
        logId: Number(this.id),
      });
      console.log(data);
      this.list = data.data[0];
    },
  },
};
</script>
<style lang="less" scoped>
.sceneryLogDetails {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
}
</style>