import request from '../utils/request'
import baseUrl from './baseUrl'

export const sceneryLogList = (data) => request({
  url: baseUrl + '/sceneryLog/selectForBack',
  method: 'POST',
  data
})
export const sceneryLogDetails = (data) => request({
  url: baseUrl + '/sceneryLog/selectForId',
  method: 'POST',
  data
})